import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { LocalizedText } from "../LocalizedText";
import { CloseButton } from "../Panel";
import React from "react";
import { LocalizedButton } from "../Button";
import { UserContext } from "../../contexts/UserContext";
import { useStyles } from "./styles";

export interface OutOfDialogProps {
  show: boolean;
  DialogType: "OUT_OF_TRIAL" | "MORE_INFO";
  onClose: () => void;
  salesforceId: string;
}

export const OutOfTrialDialogView: React.FC<OutOfDialogProps> = ({
  show,
  DialogType,
  onClose,
  salesforceId,
}) => {
  const { ctxUser } = React.useContext(UserContext);
  const classes = useStyles();

  const handleUpgrade = () => {
    Intercom("trackEvent", "livecamera-watchdemo-requested_v2");

    const url =
      "https://ram2ams.azurewebsites.net/api/RAMMarketing?code=FZDqro1VuczY2-HPWgRzRoqwDUCfh0rMvKg9jRfrCjhYAzFu01v-tw==" +
      "&sf_campaign=Camera%20Package%20Upgrade&utm_medium=Upgrade%20Options&utm_campaign=Upgrade&utm_source=RAM%20Live%20Portal" +
      "&destinationURL=https%3A%2F%2Fwww2.ramtracking.com%2Fram-live-thank-you&account_id=" +
      salesforceId +
      "&user_id=" +
      ctxUser?.salesforceContactId;

    window.open(url, "_blank", "noopener");
  };

  const upgradeButton = (
    <LocalizedButton
      fullWidth
      variant="contained"
      color="secondary"
      onClick={handleUpgrade}
    >
      Upgrade Now to RAM Live Plus
    </LocalizedButton>
  );

  const outOfTrial = DialogType === "OUT_OF_TRIAL";

  const title =
    "Unlock Full Access to Your Dashcam Footage with RAM Live Plus!";

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      aria-describedby="alert-dialog-description"
      keepMounted
      open={show}
      onClose={onClose}
      style={{ padding: "100px" }}
    >
      <DialogTitle id="simple-dialog-title" disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={{ textAlign: "center" }}>
            <LocalizedText text={title} />
          </Typography>
          <CloseButton onClick={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <Typography variant="body1" paragraph>
            {outOfTrial
              ? "We hope you’ve enjoyed your 30-day free trial of RAM Live Plus!"
              : "We hope you’re enjoying your 30-day free trial of RAM Live Plus!"}
          </Typography>
          <Typography variant="body1" paragraph>
            {outOfTrial
              ? "With the Plus package, you’ve had the ability to:"
              : "With the Plus package, you currently have the ability to:"}
          </Typography>
          <ul className={classes.indentedBulletPoints}>
            <li>
              <Typography variant="body2">
                View event video footage: Instantly access video recordings tied
                to events.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Understand events in detail: Review location, date, time, type
                of event, and its severity—all in one place.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Act swiftly: Use comprehensive evidence to manage incidents with
                confidence and accuracy.
              </Typography>
            </li>
          </ul>
          <Typography variant="body2" paragraph></Typography>
          <Typography variant="body1" paragraph>
            <strong>What Happens Now?</strong>
          </Typography>
          <Typography variant="body1" paragraph>
            {outOfTrial
              ? "Your account will now revert to the Core package, which continues to provide essential event information, but without access to the video footage."
              : "Nothing! You can continue to enjoy the benefits of RAM Live Plus until the end of your trial period."}
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Ready to unlock video footage for future events?</strong>
          </Typography>
          <Typography variant="body1" paragraph>
            By upgrading to RAM Live Plus, you’ll unlock uninterrupted access
            to:
          </Typography>
          <ul className={classes.indentedBulletPoints}>
            <li>
              <Typography variant="body1">
                High-quality video footage for all events.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Seamless integration of footage with event data for complete
                context.
              </Typography>
            </li>
          </ul>
          <Typography variant="body2" paragraph></Typography>
          <Typography variant="body1" paragraph>
            Click the button below to explore upgrading and unlocking these
            powerful features:
          </Typography>
        </Box>
      </DialogContent>
      <DialogContent dividers>{upgradeButton}</DialogContent>
    </Dialog>
  );
};
