/**
 * The name for our API in the AWS Amplify SDK.
 * The actual value of this constant is unimportant,
 * it just needs to be consistent wherever we reference
 * the gateway API (e.g. when making a request using
 * the Amplify API class)
 */
export const apiName = "RamLiveGatewayApi";

/**
 * The base URL for the API.
 */
export const apiUrl = process.env.STORYBOOK
  ? process.env.STORYBOOK_API_URL
  : process.env.REACT_APP_API_URL;

/**
 * Show summary tab in vehicle view
 */
export const showSummary = process.env.STORYBOOK
  ? process.env.STORYBOOK_SHOW_VEHICLE_SUMMARY === "1" ||
    process.env.STORYBOOK_SHOW_VEHICLE_SUMMARY === "true"
  : process.env.REACT_APP_SHOW_VEHICLE_SUMMARY === "1" ||
    process.env.REACT_APP_SHOW_VEHICLE_SUMMARY === "true";

/**
 * API key for all Google map usage
 */
export const googleMapsKey =
  process.env.REACT_APP_GOOGLE_MAPS_KEY ||
  process.env.STORYBOOK_GOOGLE_MAPS_KEY;

/**
 * The key under which to store the fact that
 * the user has asked not to see confirmations
 * of media requests
 */
export const seenMediaDialogKey = "ramliveSeenMediaDialog";

/**
 * The name of the admin role
 */
export const adminRole = "SYSTEM_ADMIN";
export const ssoAdminRole = "RAM Admin";

/**
 * Labels and API values for event types and severities
 *
 */
export const types = [
  { label: "vod", value: "VOD" },
  { label: "manual", value: "MANUAL" },
  { label: "impact", value: "IMPACT" },
  { label: "cornering", value: "CORNERING" },
  { label: "acceleration", value: "ACCELERATION" },
  { label: "braking", value: "BRAKING" },
  { label: "bump", value: "BUMP" },
  { label: "hyperlapse", value: "HYPERLAPSE" },
  { label: "eye_close", value: "EYE_CLOSE", show: false },
  { label: "yawning", value: "YAWNING", show: false },
  { label: "distraction", value: "DISTRACTION", show: false },
  { label: "smoking", value: "SMOKING", show: false },
  { label: "phone_use", value: "PHONE_USE", show: false },
  { label: "driver_abnormal", value: "DRIVER_ABNORMAL", show: false },
  { label: "ir_blocking", value: "IR_BLOCKING", show: false },
  { label: "seatbelt_unfastened", value: "SEATBELT_UNFASTENED", show: false },
];
export const severities = [
  { label: "mild", value: "MILD" },
  { label: "moderate", value: "MODERATE" },
  { label: "severe", value: "SEVERE" },
];

export const mediaTypes = [
  { label: "image", value: "IMAGE" },
  { label: "video", value: "VIDEO" },
  { label: "hyperlapse", value: "HYPERLAPSE" },
];

/**
 * RAM contact details
 */
export const contactDetails = (
  country: string
): {
  phone?: string;
  fax?: string;
  email?: string;
  line1?: string;
  line2?: string;
  city?: string;
  postcode?: string;
  description?: string;
} => {
  switch (country) {
    case "US":
      return {
        email: "us-info@ramtracking.com",
        phone: "1-855-265-6645",
        line1: "104 North Main Street",
        line2: "Suite 400B",
        city: "Gainesville, Florida",
        postcode: "FL 32601",
        description:
          "RAM Tracking is a trading name of Asset Monitoring Solutions Inc.",
      };
    case "CA":
      return {
        email: "info@ramglobalsolutions.ca",
        phone: "1-647-360-3118",
        line1: "2275 Upper Middle Rd. E",
        line2: "Suite 101 Oakville",
        city: "Ontario",
        postcode: "L6H 0C3",
        description:
          "RAM Tracking is a trading name of RAM Global Solutions (Canada) Ltd.",
      };
    case "GB":
    default:
      return {
        phone: "03301003622",
        line1: "First Floor, Nelson House",
        line2: "George Mann Road, Quayside",
        city: "Leeds",
        postcode: "LS10 1DJ",
        fax: "08707585881",
        email: "live.support@ramtracking.com",
        description: `RAM Tracking is a trading name of Remote Asset Management Ltd.
        <br />
        Registered in England, Company Registration 5224605, VAT number
        847271117`,
      };
  }
};

/**
 * Speed colours. Used in various map views to colour markers based on vehicle
 * speed
 */
export const speedColors = {
  speed1: "#58B331",
  speed2: "#73C32B",
  speed3: "#A3D432",
  speed4: "#CBE236",
  speed5: "#FDFA38",
  speed6: "#FDD239",
  speed7: "#EA9736",
  speed8: "#E46439",
  speed9: "#DC3434",
};
