export const calculateRemainingDays = (
  trialStartDateString: string | undefined
): number => {
  const trialDurationInDays = 30;

  if (!trialStartDateString) {
    return 0;
  }

  const trialStartDate = new Date(trialStartDateString);

  // Check if the parsed date is valid
  if (isNaN(trialStartDate.getTime())) {
    return 0; // Return 0 if the date is invalid
  }

  const trialEndDate = new Date(trialStartDate);
  trialEndDate.setDate(trialEndDate.getDate() + trialDurationInDays);

  const currentDate = new Date();
  const timeDifference = trialEndDate.getTime() - currentDate.getTime();
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  // Return 0 if trial has expired, otherwise return the remaining days
  return daysRemaining > 0 ? daysRemaining : 0;
};
