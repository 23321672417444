import React, { useContext } from "react";
import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { useIsMobileSize } from "../../hooks/is-mobile-size";
import { LayoutProps } from "./interfaces";
import { useStyles } from "./styles";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";

export const Layout: React.FC<LayoutProps> = ({ menuCollapsed, children }) => {
  const classes = useStyles();

  const { isTabletSize, isMobileSize } = useIsMobileSize();
  const auth: IAuthContext = useContext(AuthContext);

  const handleSignOut = () => {
    auth.logOut();
    window.location.replace(
      process.env.REACT_APP_SSO_API_URL +
        "/logout?redirect=" +
        process.env.REACT_APP_SSO_REDIRECT_URL
    );
  };

  return (
    <div className={classes.layout}>
      {!isTabletSize && !isMobileSize && <Header collapsed={menuCollapsed} />}
      <article className={classes.container}>
        <Sidebar collapsed={menuCollapsed} onSignOut={handleSignOut} />

        {children}
      </article>
    </div>
  );
};
