import React, { useContext } from "react";
import { EventContext } from "../EventDetail/EventContext";
import { EventCameraMediaProps } from "./interfaces";
import { EventCameraMediaView } from "./views";
import { UserContext } from "../../contexts/UserContext";
import { useAccountsApi } from "../../hooks/use-accounts";
import { Account } from "../../interfaces";

export const EventCameraMedia = (props: EventCameraMediaProps): JSX.Element => {
  const { ctxUser } = useContext(UserContext);
  const { getAccount } = useAccountsApi();
  const { requestImage, requestVideo } = React.useContext(EventContext);
  const [accountData, setAccountData] = React.useState<Account | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!ctxUser || !ctxUser.accountId) return;

    async function fetchData() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = await getAccount(ctxUser!.accountId);
      setAccountData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [ctxUser?.accountId]);

  return (
    <EventCameraMediaView
      {...props}
      showTools={props.showTools !== undefined ? props.showTools : true}
      onRequestImage={requestImage}
      onRequestVideo={requestVideo}
      currentUserId={ctxUser?.id}
      account={accountData}
    />
  );
};
